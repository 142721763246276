<template>
  <div class="pipeline-setting">
    <div class="header">
      <div class="titleG">
        <div
          @click="handelCancel($event)"
          class="fleche"
          v-if="$route && $route.name === 'categorieProjetPipeline'"
        >
          <font-awesome-icon class="" icon="arrow-circle-left" />
        </div>
        <div class="titre">
          <h4>Gestion des pipelines</h4>
        </div>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="success"
          @click="addOuvertPipeline"
          v-if="
            $route &&
              $route.name === 'categorieProjetPipeline' &&
              (!pipelinesPerProjectsTypes || !pipelinesPerProjectsTypes.length)
          "
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter un pipeline
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="addOuvertPipeline"
          v-if="$route && $route.name === 'pipeline'"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter un pipeline
        </b-button>
      </div>
    </div>
    <div class="message mt-2">
      <div v-if="getPipelinesLoading" class="three-dots-loading">
        Chargement en cours
      </div>
      <div v-if="getPipelinesError" class="error">
        <ul v-if="Array.isArray(getPipelinesError)">
          <li v-for="(e, index) in getPipelinesError" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ getPipelinesError }}</span>
      </div>
    </div>
    <hr />
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <b-list-group v-else>
      <b-list-group-item
        class="titre"
        v-for="(type, index) in pipelinesPerProjectsTypes"
        :key="index"
        :value="index.id"
      >
        <div class="content" v-if="type">
          <!-- <div class="nom-type">{{ type.name }}</div> -->
          <div
            class="globalpipeline"
            v-for="(pipeline, pIndex) in type.pipelines"
            :key="pIndex"
          >
            <ul class="pipeline">
              <li>{{ pipeline.name }}</li>
              <span
                v-if="pipeline && pipeline.dtype_name"
                class="type-pipeline"
              >
                (projet : {{ pipeline.dtype_name }} )</span
              >
            </ul>
            <div class="actions">
              <div
                class="div-flex"
                v-if="$route && $route.name != 'categorieProjetPipeline'"
              >
                <div>Principal</div>
                <b-form-checkbox
                  class="switch ml-2 mr-2"
                  v-model="pipeline.is_principal"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  @change="
                    updatePipelinePrincipale(pipeline, pipeline.is_principal)
                  "
                >
                </b-form-checkbox>
              </div>
              <div class="div-flex">
                <div>Lié à PIXEL :</div>
                <b-form-checkbox
                  class="switch ml-2"
                  v-model="pipeline.linked_to_pixel"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  @change="
                    updatePipelineIDPixel(pipeline, pipeline.linked_to_pixel)
                  "
                >
                </b-form-checkbox>
              </div>
              <div class="div-flex ">
                <div class="ml-2 mr-2 select">Catégorie :</div>
                <multiselect
                  :disabled="pipeline && pipeline.is_principal == 1"
                  class="select-category"
                  v-model="pipeline.objetcategory"
                  :options="
                    [{ id: null, name: '' }, ...pipeline.table_category] || []
                  "
                  @input="
                    updatePipelineCategory(pipeline, pipeline.objetcategory)
                  "
                  placeholder="Rechercher Catégorie"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="true"
                  label="name"
                  track-by="id"
                  :preselect-first="false"
                >
                  <span slot="noResult">Aucun option trouvé.</span>
                  <span slot="noOptions">Aucun option trouvé.</span>
                </multiselect>
              </div>
              <div class="ml-2 btn-crud">
                <div>
                  <router-link
                    v-if="$route && $route.name === 'categorieProjetPipeline'"
                    :to="
                      '/setting/categorie-projet/' +
                        pipeline.category_id +
                        '/pipeline/' +
                        pipeline.id
                    "
                    class="user-link"
                  >
                    Modifier
                  </router-link>
                  <router-link
                    v-else
                    :to="'/setting/pipeline/' + pipeline.id"
                    class="user-link"
                  >
                    Modifier
                  </router-link>
                </div>
                <div>
                  <a href="#" @click.prevent.stop="handleDeleteClick(pipeline)">
                    Supprimer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="aucun-pipeline"
        v-if="
          (!pipelinesPerProjectsTypes || !pipelinesPerProjectsTypes.length) &&
            !initLoading
        "
        >Aucun pipeline</b-list-group-item
      >
    </b-list-group>
    <!-- Add Pipline Modal -->
    <b-modal
      no-close-on-backdrop
      id="addPiplinessModal"
      ref="addPiplinessModal"
      title="Ajouter un pipeline"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleSubmit">
        <b-form-group label="Nom de pipeline" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="pipelineToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Nom de type" label-for="type-input">
          <b-form-select id="type-input" v-model="pipelineToAdd.type">
            <option
              v-for="type in getProjectsTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </b-form-select>
        </b-form-group>
        <div class="form-actions">
          <b-button
            @click="hideModal('addPiplinessModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
        <div class="message">
          <div v-if="getPipelinesLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getPipelinesError" class="error">
            <ul v-if="Array.isArray(getPipelinesError)">
              <li v-for="(e, index) in getPipelinesError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getPipelinesError }}</span>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- END Add Pipline Modal -->
    <!-- Delete Pipeline Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalPipelineDelete"
      id="ModalPipelineDelete"
      title=" Supprimer un pipeline"
      :hide-footer="true"
    >
      <div>
        <p class="parg">
          Êtes-vous certain de vouloir supprimer le pipeline :
          <strong> {{ pipelineToDelete ? pipelineToDelete.name : '' }}</strong>
          ?
        </p>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalPipelineDelete')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleModalDelete"
          >Valider</b-button
        >
      </div>
      <div class="message">
        <div v-if="getPipelinesLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getPipelinesError" class="error">
          <ul v-if="Array.isArray(getPipelinesError)">
            <li v-for="(e, index) in getPipelinesError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getPipelinesError }}</span>
        </div>
      </div>
    </b-modal>
    <!-- END Delete Pipeline Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      pipelineToAdd: {
        name: null,
        type: null
      },
      pipelineToUpdate: null,
      pipelineToDelete: null,
      initLoading: true,
      categoryPipeline: false
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchPipelinesPipelines',
      'addPipelinesPipeline',
      'deletePipelinesPipeline',
      'fetchPipelinesCategory',
      'fetchPipelinesCategoriesPipelines',
      'updatePipelinesPipeline',
      'resetErrorPipeline'
    ]),
    handelCancel() {
      if (this.$route && this.$route.name === 'categorieProjetPipeline') {
        this.$router.replace('/setting/categorie-projet').catch(() => {})
      }
    },
    async updatePipelinePrincipale(pipeline, is_principal) {
      let var_is_principal = { is_principal: is_principal }
      const response = await this.updatePipelinesPipeline({
        pipeline: pipeline,
        data: var_is_principal
      })
      if (!response) {
        pipeline.is_principal = is_principal == 1 ? 0 : 1
      } else {
        if (pipeline) {
          for (
            let index = 0;
            index < this.getPipelinesPipelines.length;
            index++
          ) {
            if (
              this.getPipelinesPipelines[index].type &&
              pipeline.type &&
              this.getPipelinesPipelines[index].type.id == pipeline.type.id &&
              this.getPipelinesPipelines[index].is_principal == 1 &&
              this.getPipelinesPipelines[index].id != pipeline.id
            ) {
              let var_is_principal = { is_principal: 0 }
              await this.updatePipelinesPipeline({
                pipeline: this.getPipelinesPipelines[index],
                data: var_is_principal
              })
            }
          }
        }
      }
    },
    async updatePipelineIDPixel(pipeline, id_pixel) {
      let var_id_pixel = { pixel_id: id_pixel }
      const response = await this.updatePipelinesPipeline({
        pipeline: pipeline,
        data: var_id_pixel
      })
      if (!response) {
        pipeline.linked_to_pixel = 0
      }
    },
    async updatePipelineCategory(pipeline, category) {
      let objetcategory = {}
      objetcategory = { id: category.id, category: true }
      await this.updatePipelinesPipeline({
        pipeline: pipeline,
        data: objetcategory
      })
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.resetErrorPipeline()
      this.pipelineToAdd = {
        name: null,
        type: null
      }
    },
    handleDeleteClick(pipelineDelete) {
      this.pipelineToDelete = { ...pipelineDelete }
      this.resetErrorPipeline()
      this.$refs['ModalPipelineDelete'].show()
    },
    addOuvertPipeline() {
      this.resetErrorPipeline()
      this.$refs['addPiplinessModal'].show()
    },
    async handleSubmit() {
      if (
        this.$route &&
        this.$route.name === 'categorieProjetPipeline' &&
        this.$route.params &&
        this.$route.params.id
      ) {
        this.pipelineToAdd.category_id = this.$route.params.id
      }
      const response = await this.addPipelinesPipeline(this.pipelineToAdd)
      if (response) {
        this.hideModal('addPiplinessModal')
        if (this.getPipelineCategory && this.getPipelineCategory.length) {
          for (
            let index = 0;
            index < this.getPipelineCategory.length;
            index++
          ) {
            let pipeline = this.getPipelineCategory[index]
            if (pipeline && typeof pipeline.id == 'number') {
              this.fetchPipelinesCategory(pipeline)
            }
            pipeline.objetcategory = {
              name: pipeline.categorie_name,
              id: pipeline.category_id
            }
          }
        }
      }
    },
    async handleModalDelete() {
      const response = await this.deletePipelinesPipeline(
        this.pipelineToDelete.id
      )
      if (response) {
        this.hideModal('ModalPipelineDelete')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPipelinesPipelines',
      'getProjectsTypes',
      'getPipelinesLoading',
      'getPipelinesError',
      'getPipelineCategory'
    ]),
    pipelinesPerProjectsTypes: function() {
      let list = []
      if (this.$route && this.$route.name == 'categorieProjetPipeline') {
        this.getPipelineCategory.forEach(pipeline => {
          if (!list[pipeline.id]) {
            list[pipeline.id] = {
              name: pipeline.name,
              pipelines: []
            }
          }
          list[pipeline.id].pipelines.push(pipeline)
        })
        return list.filter(e => e)
      } else {
        this.getPipelinesPipelines.forEach(pipeline => {
          if (!list[pipeline.id]) {
            list[pipeline.id] = {
              name: pipeline.name,
              pipelines: []
            }
          }
          list[pipeline.id].pipelines.push(pipeline)
        })
        return list.filter(e => e)
      }
    }
  },
  watch: {
    async $route(route) {
      if (route && route.name === 'categorieProjetPipeline') {
        this.categoryPipeline = true
        if (route && route.params) {
          await this.fetchPipelinesCategoriesPipelines(route.params.id)
        }
        if (this.getPipelineCategory && this.getPipelineCategory.length) {
          for (
            let index = 0;
            index < this.getPipelineCategory.length;
            index++
          ) {
            let pipeline = this.getPipelineCategory[index]
            if (pipeline && typeof pipeline.id == 'number') {
              this.fetchPipelinesCategory(pipeline)
            }
            pipeline.objetcategory = {
              name: pipeline.categorie_name,
              id: pipeline.category_id
            }
          }
        }
      } else {
        this.categoryPipeline = false
        await this.fetchProjetsTypes()
        await this.fetchPipelinesPipelines({ is_hidden: 1 })
        for (
          let index = 0;
          index < this.getPipelinesPipelines.length;
          index++
        ) {
          let pipeline = this.getPipelinesPipelines[index]
          if (pipeline && typeof pipeline.id == 'number') {
            this.fetchPipelinesCategory(pipeline)
          }
          pipeline.objetcategory = {
            name: pipeline.categorie_name,
            id: pipeline.category_id
          }
        }
      }
    }
  },
  async mounted() {
    if (this.$route && this.$route.name === 'categorieProjetPipeline') {
      this.categoryPipeline = true
      if (this.$route && this.$route.params) {
        await this.fetchPipelinesCategoriesPipelines(this.$route.params.id)
      }
      if (this.getPipelineCategory && this.getPipelineCategory.length) {
        for (let index = 0; index < this.getPipelineCategory.length; index++) {
          let pipeline = this.getPipelineCategory[index]
          if (pipeline && typeof pipeline.id == 'number') {
            this.fetchPipelinesCategory(pipeline)
          }
          pipeline.objetcategory = {
            name: pipeline.categorie_name,
            id: pipeline.category_id
          }
        }
      }
    } else {
      this.categoryPipeline = false
      await this.fetchProjetsTypes()
      await this.fetchPipelinesPipelines({ is_hidden: 1 })
      for (let index = 0; index < this.getPipelinesPipelines.length; index++) {
        let pipeline = this.getPipelinesPipelines[index]
        if (pipeline && typeof pipeline.id == 'number') {
          this.fetchPipelinesCategory(pipeline)
        }
        pipeline.objetcategory = {
          name: pipeline.categorie_name,
          id: pipeline.category_id
        }
      }
    }

    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.pipeline-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    .titleG {
      display: flex;
      align-items: center;
    }
    .titre {
      margin-left: 10px;
    }
    .fleche {
      padding-bottom: 8px;
      font-size: 22px;
      cursor: pointer;
    }
  }
  .list-group {
    .aucun-pipeline {
      color: #007bff;
      text-align: center;
    }
    .list-group-item {
      p {
        margin-top: 0;
        margin-bottom: -1rem;
      }
      .content {
        .nom-type {
          font-weight: bold;
          text-transform: capitalize;
        }
        .globalpipeline {
          display: flex;
          align-items: center;
          justify-content: space-between;
          ul {
            margin-top: 0;
            margin-bottom: 0rem;
            margin-bottom: 4px;
          }
          .type-pipeline {
            color: #5290ff;
            font-weight: normal;
            font-size: 12px;
          }
          .actions {
            display: flex;
            align-items: center;
            .div-flex {
              display: flex;
              align-items: center;
              .select-category {
                width: 180px;
              }
            }
          }
        }
      }
      .mt-2 {
        ul {
          margin-top: 5px;
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .header {
      white-space: unset;
    }
    .list-group {
      .list-group-item {
        .content {
          .globalpipeline {
            display: block;
            .actions {
              display: block;
              .div-flex {
                margin-bottom: 4px;
              }
              .select {
                margin-left: 0px !important;
              }
              .btn-crud {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
    .pipeline {
      margin-left: -31px;
    }
  }
}
</style>
<style lang="scss">
#ModalPipelineDelete {
  strong {
    text-transform: capitalize;
  }
  .message {
    .error {
      padding: 25px;
      color: #e73e01;
    }
  }
  p {
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 1rem;
  }
}
</style>
